// Packages:
import React, { useEffect, useState, createContext } from "react";
import styled from "styled-components";
import { Link, Navigate, Outlet, useNavigate,useParams } from "react-router-dom";

// import Collapsible from 'react-collapsible'
import axios from "axios";

// Constants:
import COLORS from "../../styles/colors";

// Components:
import { AUTH_HEADERS } from "../../api/endpoints";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import { LOCAL_STORAGE_KEYS } from "../../constants/localStorage";
import UserSubscription from "../UserSubscription";
import { fetchVaultReceiptData } from "../../redux/actions/vault-receiptAction";
import GetFiles from "./GetFiles";
import { BASE } from "../../api/endpoints";
import GetBusinesses from "./GetBusinesses";
import { updateVaultReceiptBusinessData,fetchVaultReceiptBusinessData } from "../../redux/actions/receiptVaultBusinessAction";
import EachBusiness from "./EachBusiness";
import { Margin } from "@mui/icons-material";

// Functions:
const UpdateBusiness = () => {
  const [emptyFieldError, setEmptyFieldError] = useState("");
  const [show, toggleShow] = useState(false);
  const [selectedFile, setSelectedFile] = useState([]);
  const [fileName, setFileName] = useState("");
  const [extension, setExtension] = useState("");
  const [updateData,setBusiness]=useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Add Client

  const getDate = () => {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    today = yyyy + "-" + mm + "-" + dd;
    return today;
    // document.getElementById("date").value = today;
  };
  const todayDate = getDate();
  const [value, setValue] = useState(todayDate);

  const [clientsName, setClientsName] = useState("");
  const [date, setDate] = useState(todayDate);
  const toPostLastUpdatedDate = date && new Date(date).toISOString();
  const allClients = useSelector(
    (state) => state.allClientsData.clientsdata
  );
  console.log(allClients)

  const { id } = useParams();

  useEffect(() => {
    if(id){
        const singleBusiness = allClients.data.filter((ele) => {
          return ele.id == id;
        });
        setBusiness(singleBusiness[0]);
       setClientsName(singleBusiness[0].name)
    }
  }, [id]);

  const handleUpdate = (e)=>{

    e.preventDefault();
    dispatch(updateVaultReceiptBusinessData(id,updateData ))

  }
  const showUpdatesAlert = () => {
    swal({
      text: "Update Business Updated Successfully!..",
      icon: "success",
      button: "Close",
    })
    .then(() => {
      navigate("/vault-receipt") 
      dispatch(fetchVaultReceiptBusinessData());
      
    });
  }




  return (
   
        <VaultReceiptForm onSubmit={handleUpdate}>
          <InputDivField>
            <div className="form-control">
              <label htmlFor="businessName">Business</label>
              <input
                type="text"
                id="businessName"
                name="businessName"
                value={updateData && updateData.name}
                onChange={(e) => setBusiness({...updateData,name:e.target.value}) }
              ></input>
            </div>

            <div className="form-control">
              <label htmlFor="date">Date Added</label>
              <input
                type="date"
                id="date"
                name="date"
                value={value}
                max={new Date().toISOString().split("T")[0]}
                onChange={(e) => [
                  setDate(e.target.value),
                  setValue(updateData && updateData.date),
                  setBusiness({...updateData,date:e.target.value})
                ]}
              ></input>
            </div>
          </InputDivField>
          <div style={{ width: "100%", fontSize: "16px", color: "red" }}>
            {" "}
            {emptyFieldError}
          </div>
          <div className="flex">
          <button
              type="submit"
              className="submitButton"
              onClick={showUpdatesAlert}
              style={{marginBottom:10}}
            >
              Update
            </button>
          <button  className="submitButton" onClick={() => [navigate("/vault-receipt"), dispatch(fetchVaultReceiptBusinessData())]} > Cancel </button>
          {/* <SubmitButton onClick={showUpdatesAlert}>Update</SubmitButton> */}

          </div>
        </VaultReceiptForm>
     
  );
};

// Exports:
export default UpdateBusiness;

// Styles:
const Wrapper = styled.div`
  position: absolute;
  left: 20vw;
  top: 3.75rem;
  width: 80vw;
  /* height: 100vh; */
  /* max-height: calc(100vh - 3.75rem); */
  height: calc(100vh - 3.75rem);
  padding: 1.5rem;
  background-color: #f5f3f3;
  overflow: auto;
  float: left;

  @media screen and (min-width: 768px) and (max-width: 992px) {
    font-size: 1rem;
    font-weight: 400;
    width: 100vw;
    left: 0;
  }

  @media screen and (min-width: 576px) and (max-width: 768px) {
    font-size: 1rem;
    font-weight: 400;
    width: 100vw;
    left: 0;
  }
  @media screen and (min-width: 200px) and (max-width: 576px) {
    font-size: 12px;
    font-weight: 400;
    width: 100vw;
    left: 0;
  }
`;

const EditButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const EditButton = styled.div`
  padding: 0.25rem 1.5rem;
  font-weight: 500;
  color: #ffffff;
  background-color: ${COLORS.PRIMARY};
  user-select: none;
  cursor: pointer;
`;
const AddnewButton = styled.button`
  border: none;
  background-color: #002857;
  color: white;
  border-radius: 3px;
  margin-bottom: 10px;
  float: right;
  padding: 2px 1rem;
  min-width: 50px;
  width: auto;
  height: 50px;
  font-size: 15px;
`;

const TopHeaderDiv = styled.div`
  position: sticky;
  top: -1.8rem;
  background-color: white;
  z-index: 9;
  padding: 4px 5px;
`;

const VaultReceiptForm = styled.form`
  padding: 0;
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 100%;
`;

const InputDivField = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;
const SubmitButton = styled.button`
  border: none;
  background-color: #002857;
  color: white;
  border-radius: 3px;
  margin-bottom: 10px;
  float: right;
  padding: 2px 1rem;
  min-width: 50px;
  width: 100px;
  margin-left: 10px;
  /* height: 50px; */
  font-size: 20px;
  &:hover {
    color: #002758;
    background-color: #fff;
    border: 1px solid #002758;
  }
`;
