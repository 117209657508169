import React from 'react'
import styled from "styled-components";
import COLORS from "../../styles/colors";

const PrivacyPolicy = () => {
  return (
    <>
      <Wrapper>
        <TopHeaderDiv>
          <span style={{ fontSize: "2.0rem", fontWeight: "700" }}>
            Privacy Policy
          </span>
        </TopHeaderDiv>
        <div classname="mt-5" style={{minHeight: '500px',overflowY: 'scroll',  height: '300px',marginTop:'40px'}}>
          <h2>Introduction</h2>
          <p>
            CashDiary is a Social Media platform used by its customers through the web and mobile applications to find local information and business advertisement. Therefore, all our clients and other users, in general, are subject to this privacy policy.
            All client information is also protected against misuse and illegal access. We do not store any financial data on our servers, such as credit card information.
          </p>
          <h2>Services</h2>
          <p>We at CashDiary are committed to your privacy regarding any information that we collect, while you use our software applications that consist of various modules, and all such products, applications, websites are collectively called ‘Services’ . This Privacy Policy applies when you use our Services.
            CashDiary operates a web-based and mobile application called CashDiary and white label apps locally which is a part of the service that we offer.</p>
          <h2>Data Controller and Data Processor</h2>
          <p>
            CashDiary acts as both the data controller and data processor for the personal data processed through our services.
            Information We Collect</p>
          <h2>Customer Data</h2>
          <p>As a customer, you provide us with data for processing when you use our CashDiary Portal Services application. This customer data may include personal information of visitors and others. It encompasses data such as contact details and any other information necessary for the proper functioning of our portal management system.</p>
          <h2>Other Data</h2>
          <p>In addition to customer data, we collect other data when you interact with our applications and services. This may include information you provide during the account creation process, such as name, email address, phone number, job title, and correspondence address. We also collect log data, data from subscriptions, contact information provided when you reach out to us, and other information obtained from social media platforms.</p>
          <h2>Usage of Customer Data</h2>
          <p>We process customer data on behalf of our customers and in accordance with their instructions and contractual agreements. CashDiary Portal Services Pvt. Ltd, acts as the data processor for customer data, and our customers retain control over their customer data. We utilize customer data to provide the agreed-upon services, such as managing client or business information, facilitating communication, and advertising.</p>
          <h2>Usage of Other Data</h2>
          <p>We use other data for various purposes, including sending newsletters, responding to inquiries, providing customer support, and marketing our services. We may also use this data to communicate updates and modifications to our services, as well as to invite you to relevant conferences and events, subject to your consent.</p>
          <h2>Legal Bases for Processing User Data</h2>
          <p>
            We process your personal data based on legal justifications, including the fulfillment of contracts, legitimate interests, and consent. The specific legal basis for processing customer data is the fulfillment of the customer agreement, while other data is processed based on legitimate interests or consent. In some cases, we may be required by law to collect certain personal data from you.</p>
            <h2>Data Retention Policy</h2>
            <p>We retain your personal information only for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law. Customer data is retained as long as the customer's account is active or as specified in the customer agreement. Other data is kept for the duration necessary to achieve the purposes stated in this policy.</p>
            <h2>Cross-Border Data Transfers</h2>
            <p>In order to provide our services, your personal data may be transferred to locations outside your country of residence. We take appropriate security measures and use safeguards, such as standard data protection clauses adopted by the relevant authorities, to ensure the protection of your personal information during international transfers.</p>
            <h2>Your Rights and Control Over Data</h2>
            <p>You have certain rights and control over your personal data. Subject to applicable laws, you have the right to access, update, correct, or delete your personal information. You may also object to direct marketing communications and have the right to request the portability, restriction, or deletion of your personal data. You can exercise these rights by contacting us using the information provided below.</p>
            <h2>Sharing Your Data with Third Parties</h2>
            <p>We may share your data with third-party service providers and recipients necessary to deliver our services, such as payment gateways, hosting services, email marketing providers, and communication channels. We may also disclose your information if required by law or in response to a legal request or process.</p>
            <h2>Data Security Measures</h2>
            <p> We have implemented appropriate security measures to protect your personal information from unauthorized access, loss, disclosure, alteration, or destruction. These measures include physical, technical, and administrative safeguards designed to secure and protect the confidentiality, integrity, and availability of your personal data. <br/> We collect various types of data when you interact with our applications or websites or provide us with information directly. The types of data we may collect include:</p>
            <h2>1.1 Log Data</h2>
            <p>When you visit or use our applications or services, our servers automatically record certain information in log files. This may include your IP address and other details about your device, browser type, operating system, referral URLs, pages visited, and other usage information.</p>
            <h2>1.2 Data from Subscriptions</h2>
            <p>When you request a product demo or sign up for one of our newsletters, we collect the data you provide during the subscription process.</p>
            <h2>1.3 Contact Us Information</h2>
            <p>If you contact us with questions about our goods and services, we collect and store the information you provide to us, such as your name, email address, phone number, and any other details necessary to address your inquiry.</p>
            <h2>1.4 Conferences and Seminars</h2>
            <p>If you participate in our conferences or seminars, we collect and retain the data you provide during these events.</p>
            <h2>1.5 Other Information</h2>
            <p>In addition to the information you provide, we may also obtain information from social media networks, such as LinkedIn, for marketing and sales purposes.</p>
            <h2>Use of Personal Data:</h2>
            <p>We use your personal data for the following purposes:</p>
            <h2>2.1 Services Usage</h2>
            <p>The way we use your personal data depends on the Services you use and how you use them. We process customer data as instructed by our customers and in accordance with applicable laws. As a processor, we follow the customer's requests and the provisions of the Customer Agreement.</p>
            <h2>2.2 Newsletters and Communication</h2>
            <p>We may use the data you provide to send newsletters, updates, promotional materials, and other communications related to our goods and services. We may also use your personal data to respond to your inquiries, comments, and other correspondence.</p>
            <h2>3.Data Security and Retention</h2>
            <p>We employ appropriate technical and organizational measures to protect your personal data against unauthorized access, alteration, disclosure, or destruction. We retain your personal data for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.</p>
            <h2>4.Your Rights</h2>
            <p>You have certain rights regarding your personal data, including the right to access, correct, delete, or restrict its processing. You may also have the right to object to the processing of your personal data or to receive a copy of your data in a structured, machine-readable format. To exercise these rights or for any privacy-related concerns, please contact us using the information provided below.</p>
            <h2>5.Third-Party Disclosure</h2>
            <p>We do not sell, trade, or otherwise transfer your personal data to third parties without your consent, except as described in this Privacy Policy or as required by law. We may share your personal data with trusted third-party service providers who assist us in operating our Services, conducting business activities, or serving you, provided that they agree to keep your information confidential and use it only for the purposes specified by us.


CashDiary shall utilize Customer Data following the customer’s requests, applicable provisions of the Customer Agreement, and as mandated by applicable law. The customer is the controller of the customer data, and CashDiary is the processor of that data.
 
Other data is used to send newsletters and reply to inquiries, comments, and other correspondence from you.</p>
<h2>Legal bases for processing user data</h2>
<p>We process your personal data on a legal basis. We have a legitimate interest in processing your data, and in some situations, we rely on your consent.
 
We only process your personal data when we have a valid reason. For the time being, our legal justification for processing customer data is the fulfilment of the contract, and for processing other data, it is a legitimate interest or consent. However, in rare circumstances, we may also be required by law to get personal data from you.
 
You have the right to revoke your permission at any moment when you have given it for a specific procedure.
 
How do we use customer data?

To authenticate and approve access to our services, we use your data.
 
Only on behalf of our clients and in compliance with their instructions stated in the relevant Services Agreement with us do we process Customer Data. We utilize your data to enhance your experience and assist you with our service. In every situation, CashDiary only gathers this data if necessary or appropriate to achieve the goals of the user’s interaction with our services.
</p>
<h2>Delivering services:</h2> 
<p>Through the CashDiary ERP platform, we process customer data in our capacity as a data processor to offer services to our clients.
To communicate by email and other means: Emails, messages, and other sorts of communications regarding services, technology, and other administrative matters may be sent to you. We may also get in touch with you to let you know about modifications to our services and their availability. You cannot opt-out of receiving these communications unless you decide not to use our services, as they are deemed to be part of the Services.</p>
<h2>Customer service:</h2>
<p>We reply to your request and help you resolve the issue more quickly if you communicate it to us (for instance, via a support email or one of our feedback systems).</p>
<h2>How Do We Use Other Data?</h2>
<p>We might send you messages about our services or marketing- or promotional-related items. You can limit how your personal information is collected or used.
 
We use Other Data to send you corporate updates and promotional materials for your requested product demo or invite you to conferences and events that interest you. We only engage in direct marketing if you agree to receive communications from us in this manner.</p>
<h2>Data Retention Policy</h2>
<p>Unless a longer retention time is required or permitted by law, we will only keep your personal information for as long as it is necessary to achieve the goals mentioned in this privacy policy.</p>
<p><b>Customer Data:</b> We keep your data as long as your Services account is active. Following any applicable statutory laws, we may also keep your personal information for longer.<br></br> CashDiary shall keep customer data for the time specified by the customer, as well as any conditions specified in the customer agreement and as mandated by applicable legislation. Per your instructions, we will delete or return all Customer Data when you cease using our services.</p>

<p><b>Other Data</b> – We keep your information for as long as it’s required to fulfill the goals outlined in this privacy statement.
 
CashDiary may keep your personal Other Information for as long as required to fulfill the goals outlined in this privacy statement.</p>
<h2>Cross-Border Data Transfers</h2>
<p>For any of the purposes listed in this Privacy Policy, we may transmit personal data about you to locations outside your country of residence.
 
It’s possible that the data protection rules in these nations are lower than those in your own. Your personal information is transferred internationally following the necessary security measures (such as the standard data protection clauses adopted by the European Commission).</p>
<h2>Customer Rights</h2>
<p>You can ask for access to, updates of, or corrections to your personal data. Additionally, you are entitled to object to direct marketing.
 
Your local law that applies to the processing may grant you extra rights. For example, you have the right to object to the processing of your personal information in some circumstances, such as when it is done following the EU General Data Protection Regulation (“GDPR”) and is done so to further legitimate interests. You also have the right, under GDPR, to seek the portability of your personal data and the deletion or limitation of your personal data.</p>
<h2>Customer's Rights To Control Data</h2>
<p>We strive to offer you simple ways to access, alter, delete, object to, or restrict the use of your personal information whenever you use our services.
 
We make an effort to provide you with means to rapidly view, update, or alter your data—or to have it deleted—unless we are required by law to keep it. Access to some permissions is possible through the CashDiary application. Visitors may exercise these rights by contacting us and making a specific request.</p>
<p><b>Right to Correct:</b>You can edit some of your personal information through your account. In some circumstances, mainly if the information is erroneous, you can also request that we modify, update, or correct your data.</p>
<p><b>Right to Erase:</b> You have the right to request that we delete or remove all of your personal data (.e.g., if it is no longer necessary to provide Services to you).
Limit our use or otherwise object to its use: You can ask us to cease using all or a portion of your personal data if we are not legally permitted to do so (.e.g., if your personal data is inaccurate or unlawfully held).
<br></br>
<b>Right to Access and Take Your Data:</b> You have the right to get a copy of your machine-readable personal data that we have collected from you.
Rights pertaining to automated judgments, such as profiling
How Do We Share Your Data With Third Parties
Recipients Of Your Data
In order to offer you services, your data will be shared with other receivers. 
 
Even though we try to keep it to a minimum, there are situations when it becomes necessary to share your data with specific service providers. When and why your data is shared, for instance, depends on the data center or hosting service, email marketing service, etc.
 
Your data will probably be shared with the recipients listed below so we can provide you with our services.
 
Payment Gateways, VTS Vendors, Content Partners, etc., are examples of third-party services.

SMTP servers from third parties, email service providers like SendGrid, SMS providers like SMSCountry, Whatsapp service providers, etc.
<br></br>
<b>To Adhere to Laws:</b> In response to a request for information, if we have reason to think that disclosing is permitted or required by any relevant law, rule, or legal process, we may do so. For example, if we sell nearly all of our assets or engage in any change of control transaction, we may also disclose your information to an acquirer.</p>
<h2>Our Measures To Protect Your Data</h2>
<p>We put security procedures in place to avoid security breaches and unauthorized access to your data.
 
We keep reasonable and suitable security measures in place to guard against loss, theft, unauthorized access, disclosure, modification, and destruction of Customer Data.
 
Physical access controls, HTTPS, restricted access to data, monitoring for threats and vulnerabilities, etc., are a few security measures.
 
We only allow those with a legitimate business need-to-know access to the personal information we gather. To preserve the privacy and integrity of personal information throughout its proper life cycle, from collection to disposal, we make an effort to utilize acceptable security safeguards, methods, and procedures.</p>
<h2>Contact Information</h2>
<p>You can contact us regarding our services or this privacy statement by using the following contact details: Email: <a href='mailto:support@cashdiary.app'>support@cashdiary.app</a> <br></br>

If you have any questions or concerns about our policy, feel free to email us, and our support team will assist you.</p>
<h2>Your acceptance of these terms</h2>
<p>To use the ERP application from CashDiary, you must agree to the terms of service and this privacy policy. If you disagree with this policy, please refrain from using this application. By continuing to use the site after any modifications to this policy are posted, it will be assumed that you agree to the changes. In order for us to provide our services to you, it is necessary for you to provide us with accurate personal data as described in this policy. Failure to provide accurate information may unintentionally result in our inability to provide you with the full range of services you need.</p>
<h2>Change in the privacy policy</h2>
<p>CashDiary may occasionally modify this Privacy Policy at our sole discretion. When we make any modifications, we will update the Privacy Policy with the revision date and post it on this website. By checking the "Last Modified" date at the top of this privacy statement, you can see the last time it was updated. We encourage users of the CashDiary website to routinely check this page for updates to its privacy statement. 

Additionally, when you log in to the Sites and Services, or both, we will send you an email in advance to notify you of any significant changes.

By continuing to use our services after any modification to this privacy statement, you acknowledge that you accept the modification and agree to be bound by the modified privacy statement.</p>



        </div>
      </Wrapper>
    </>

  )
}

export default PrivacyPolicy

const Wrapper = styled.div`
  position: absolute;
  left: 20vw;
  top: 3.75rem;
  width: 78.71vw;
  height: calc(100vh - 3.75rem);
  padding: 1.5rem;

  @media screen and (min-width: 768px) and (max-width: 992px) {
    font-size: 1rem;
    font-weight: 400;
    width: 100vw;
    left: 0;
  }

  @media screen and (min-width: 576px) and (max-width: 768px) {
    font-size: 14px;
    font-weight: 400;
    width: 100vw;
    left: 0;
  }
  @media screen and (min-width: 200px) and (max-width: 576px) {
    font-size: 14px;
    font-weight: 400;
    width: 100vw;
    left: 0;
  }
`;
const TopHeaderDiv = styled.div`
  position: sticky;
  top: -1.2rem;
  background-color: white;
  z-index: 9;
  padding: 4px 5px;
`;


const Title = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  height: 2rem;
  font-size: 1rem;
  font-weight: 600;
  color: #ffffff;
`;

const Description = styled.div`
  width: 100%;
  height: 2rem;
  font-size: 0.8rem;
  font-weight: 400;
  color: #e6e6e6;
`;