export const ActionType ={
    FETCH_PASSWORD_DATA :'FETCH_PASSWORD_DATA',
    POST_PASSWORD_DATA : 'POST_PASSWORD_DATA',
    UPDATE_PASSWORD_DATA : 'UPDATE_PASSWORD_DATA',
    REMOVE_PASSWORD_DATA : 'REMOVE_PASSWORD_DATA',

    FETCH_LOCKER : 'FETCH_LOCKER',
    DELETE_LOCKER: 'DELETE_LOCKER',
    UPDATE_LOCKER: 'UPDATE_LOCKER',

    FETCH_PIN : 'FETCH_PIN',
    CREATE_PIN_ACTION: 'CREATE_PIN_ACTION',
    DELETE_PIN: 'DELETE_PIN',
    UPDATE_PIN: 'UPDATE_PIN',
    
    GET_REALESTATE : 'GET_REALESTATE',
    DELETE_REALESTATE : 'DELETE_REALESTATE' ,
    UPDATE_REALESTATE : 'UPDATE_REALESTATE',

    GET_RETAILSCASH : 'GET_RETAILSCASH',
    DELETE_RETAILCASH : 'DELETE_RETAILCASH', 

    GET_CASHDIARY : 'GET_CASHDIARY',
    DELETE_CASHDIARY : 'DELETE_CASHDIARY',

    GET_MILAGETRACKING : 'GET_MILAGETRACKING',
    GET_MILAGETRACKING_BW_DATES : `GET_MILAGETRACKING_BW_DATES`,
    UPDATE_MILAGE_TRACKING: 'UPDATE_MILAGE_TRACKING',
    DELETE_MILAGE_TRACKING:'DELETE_MILAGE_TRACKING',

    POST_VAULT_BUSINESS: 'POST_VAULT_BUSINESS',
    GET_VAULT_BUSINESS: 'GET_VAULT_BUSINESS',
    UPDATE_VAULT_BUSINESS: 'UPDATE_VAULT_BUSINESS',
    DELETE_VAULT_BUSINESS: 'DELETE_VAULT_BUSINESS',

    POST_VAULT_RECEIPT: 'POST_VAULT_RECEIPT',
    GET_VAULT_RECEIPT: 'GET_VAULT_RECEIPT',
    DELETE_VAULT_RECEIPT: 'DELETE_VAULT_RECEIPT',
    DOWNLOAD_VAULT_RECEIPT: 'DOWNLOAD_VAULT_RECEIPT',

    GET_SECRETPIN: 'GET_SECRETPIN',
    UPDATE_SECRETPIN: 'UPDATE_SECRETPIN',

    POST_EACH_TRANSACTION : `POST_EACH-TRANSACTION`,
    GET_EACH_TRANSACTION : 'GET_EACH_TRANSACTION',
    GET_EACH_TRANSACTION_BW_DATES: 'GET_EACH_TRANSACTION_BW_DATES',

    GET_USERSUBSCRIPTION: 'GET_USERSUBSCRIPTION',
    
    GET_EACH_CASHDAIRYDATA :'GET_EACH_CASHDAIRYDATA',

    GET_CASHDAIRYPAYMENT_EACH_ID : 'GET_CASHDAIRYPAYMENT_EACH_ID',

    GET_LOCATION :'GET_LOCATION',
    DELETE_LOCATION: 'DELETE_LOCATION'
}